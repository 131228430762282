import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  static LOCAL_STORAGE_KEY = 'MAALCHAT_CRM_LANGUAGE';
  storage = window.localStorage;
  constructor() { }

  setUserLanguage(language: string) {
    this.storage.setItem(I18nService.LOCAL_STORAGE_KEY, language);
  }

  getUserLanguage(): string {
    return this.storage.getItem(I18nService.LOCAL_STORAGE_KEY);
  }
}
