import {HttpRequest} from '@angular/common/http';
import {AuthService} from '@auth/auth.service';
import {Injectable} from '@angular/core';

@Injectable()
export class TokenInterceptor {

  constructor(private authService: AuthService) {
  }

  addToken(req: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();
    const countryCode = localStorage.getItem('countryCode');
    if (token) {
      const countryParam = `countryCode=${countryCode ? countryCode : 'il'}`;
      let URL = req.url.indexOf('searchKey') !== -1 ? encodeURI(req.url) : req.url;
      if (URL.indexOf('?') === -1) {
        URL = URL + '?' + countryParam;
      } else {
        URL = URL + '&' + countryParam;
      }
      return req.clone({
        headers: req.headers.set('Authorization-Token', token),
        url: URL
      });
    }
    return req;
  }
}
