import { HttpRequest } from '@angular/common/http';

import { I18nService } from '../../i18n/services';
import { Injectable } from '@angular/core';
@Injectable()
export class LanguageInterceptor {

  constructor(private i18nService: I18nService ) {}

  addLanguage(req: HttpRequest<any>): HttpRequest<any> {
    const language = this.i18nService.getUserLanguage();
    if (!language) {
      return req;
    }
    return req.clone({
      headers: req.headers.set('Accept-Language', language)
    });
  }
}
