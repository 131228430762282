export enum FolderName {
  agentLogos = 'agent-logos',
  agentProfiles = 'agent-profiles',
  adminsProfiles = 'admins-profiles',
  topUpCovers = 'topups-covers',
  topUpLogos = 'topups-logos',
  premiumAgentLogo = 'business-logos',
  images = 'images',
  nationalIDs = 'national-ids',
  brandslogos = 'brandslogos',
  vouchersLogos = 'voucherslogos',
}
