import { Injectable } from '@angular/core';

import { AuthService } from '@auth/auth.service';
import { environment } from '@environment';
import { FolderName } from '@models/index';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private authService: AuthService,
              private toastr: ToastrService,
              private http: HttpClient) { }

  /**
   * usage
   * upload().subscribe
   * .subscribe(
   * event => {
   *       if (event.type == HttpEventType.UploadProgress) {
   *         const percentDone = Math.round(100 * event.loaded / event.total);
   *         console.log(`File is ${percentDone}% loaded.`);
   *       } else if (event instanceof HttpResponse) {
   *         console.log('File is completely loaded!');
   *       }
   *     },
   * (err) => {
   *        console.log("Upload Error:", err);
   *    }, () => {
   *       console.log("Upload done");
   *     }
   * )
   * @param folderName: UploadFolderName
   * @param file: File
   * return Observable<HttpEvent<any>>
   * @param vouchersCSV
   */
  upload(folderName: FolderName, file: File, vouchersCSV = null): Promise<any> {
    const url = vouchersCSV ? `${environment.endPoint}crm/vouchers/items/${vouchersCSV.brandId}/${vouchersCSV.voucherId}/csv` : `${environment.endPoint}crm/files/${folderName}/upload`;
    const token = this.authService.getToken();
    return new Promise((resolve, reject) => {
      const formData: any = new FormData();
      const xhr = new XMLHttpRequest();

      formData.append('file', file, file.name);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      xhr.onerror = (err) => {
        console.error(err);
        if(!navigator.onLine){
          this.toastr.error('No Internet Connection');
        }
        reject(err);
      };
      xhr.open('POST', url, true);
      xhr.withCredentials = false;
      xhr.setRequestHeader('Authorization-Token', token);
      xhr.send(formData);
    });
  }



  UplaodFile(folderName: FolderName,file: File): any {





    const token = this.authService.getToken();
    console.log("-----")
    const formData = new FormData();
    formData.append('fileName', file.name);
    formData.append('file',  file);
    // formData.forEach( x => {console.log(x)})



    const url = `${environment.endPoint}crm/files/${folderName}/upload`;

    const req = new HttpRequest('POST', url, formData, {
      headers: new HttpHeaders({ 'Authorization-Token': token })
    });
    return this.http.request(req);
    // this.http.post(url, formData,specialToken).toPromise;

    // // );
    // const req = new HttpRequest('POST', url, formData, {
    //   headers: new HttpHeaders({ 'Authorization-Token': token }), reportProgress: true,
    // });
    // return this.http.request(req);
    // const specialToken = {

    //   headers: new HttpHeaders({ 'Authorization-Token': token })
    // };


    // const url = `${environment.endPoint}crm/files/${folderName}/upload`;
    // return this.http.post(url, formData, specialToken).toPromise().catch((err: Response | any) => {

    //   console.log(err)

    // });




  }
}
