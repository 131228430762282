export const getUserOperatingSystem = () => {
  let OSName = 'OTHER';
  if (window.navigator) {
    if (window.navigator.userAgent.indexOf('Windows NT 10.0')       !== -1) {
      OSName =  'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
      OSName =  'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
      OSName = 'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
      OSName = 'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
      OSName = 'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
      OSName = 'WINDOWS';
    } else if (window.navigator.userAgent.indexOf('Mac')            !== -1) {
      OSName = 'MAC_OS_X';
    } else if (window.navigator.userAgent.indexOf('X11')            !== -1) {
      OSName = 'UNIX';
    } else if (window.navigator.userAgent.indexOf('Linux')          !== -1) {
      OSName = 'LINUX';
    }
  }
  return OSName;
};

export const getUserAgentName = () => {
  const userAgentPostFix = '- CRM web App';
  if (window.navigator) {
    return `${navigator.appCodeName} ${userAgentPostFix}`;
  }
  return userAgentPostFix;
};

export const randomHex = (len) => {
  const maxlen = 8;
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n   = Math.floor( Math.random() * (max - min + 1) ) + min;
  let r   = n.toString(16);
  while ( r.length < len ) {
    r = r + randomHex( len - maxlen );
  }
  return r;
};

export const getUserDevice = () => {
  return {
    clientType: 'WEB',
    device: {
      operatingSystem: getUserOperatingSystem(),
      userAgent: getUserAgentName(),
      UDID: randomHex(8),
    }
  };
};
