import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from '@environment';
import { LoginCredentials } from '@models/login.model';
import { getUserDevice } from '@helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static LOGIN_URL = 'crm/users/auth/login';
  static TOKEN_NAME = 'MAALCHAT_TOKEN';
  static USER_ID = 'MAALCHAT_USER_ID';
  static USER_TYPE = 'MAALCHAT_USER_TYPE';
  static USER_NAME = 'USER_NAME';


  static LOGOUT_URL = 'crm/users/auth/logout';

  static TOKEN_USERNAME = 'MAALCHAT_USERNAME';

  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient) {
    this.isLoggedIn.next(!!this.getToken());
  }

  public getToken() {
    return localStorage.getItem(AuthService.TOKEN_NAME);
  }

  public getUserId() {
    return localStorage.getItem(AuthService.USER_ID);
  }
  public getUserType() {
    return localStorage.getItem(AuthService.USER_TYPE);
  }
  public getUserName() {
    return localStorage.getItem(AuthService.USER_NAME);
  }
  public setToken(token: any) {
    localStorage.setItem(AuthService.TOKEN_NAME, token);
    this.isLoggedIn.next(true);
  }
  public setUserName(userName: any) {
    localStorage.setItem(AuthService.USER_NAME, userName);
  }

  public setUserId(userId: number) {
    localStorage.setItem(AuthService.USER_ID, String(userId));
  }
  public setUserType(uType: string) {
    localStorage.setItem(AuthService.USER_TYPE, String(uType));

  }
  private removeLocalStorageItems() {
    localStorage.removeItem(AuthService.USER_ID);
    localStorage.removeItem(AuthService.TOKEN_NAME);
    this.isLoggedIn.next(false);
    return of(true);
  }

  login(loginCredentials: LoginCredentials): Observable<any> {
    const requestBody = {
      ...loginCredentials,
      ...getUserDevice(),
    };
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Accept-Language', 'en-US');
    return this.http.post(environment.endPoint + AuthService.LOGIN_URL, requestBody, { headers });
  }

  logout() {
    this.http.delete(environment.endPoint + AuthService.LOGOUT_URL).subscribe(a => {


    });
    return this.removeLocalStorageItems();
  }
}
