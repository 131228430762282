import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@auth/index';
import {VerifiedAccountsComponent} from './dashboard/verified-accounts/verified-accounts.component';
import {GatewaysRoutingModule} from "./gateways/gateways-routing.module";
import {EditPremiumAgentComponent} from "./premium-agents/edit-premium-agent/edit-premium-agent.component";

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'vouchers',
    loadChildren: './vouchers/vouchers.module#VouchersModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'contact',
    loadChildren: './contact-us/contact-us.module#ContactUsModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'agents',
    loadChildren: './agents/agents.module#AgentsModule',
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'premiumagents',
  //   loadChildren: './premium-agents/premium-agents.module#PremiumAgentsModule',
  //   canActivate: [AuthGuardService],
  // },
//   { path: 'merchants',
//   loadChildren: './merchants/merchants.module#MerchantsModule',
//   canActivate: [AuthGuardService],
// },
//   {
//     path: 'top-up-stations',
//     loadChildren: './top-up-stations/top-up-stations.module#TopUpStationsModule',
//     canActivate: [AuthGuardService],
//   },
  {
    path: 'transactions',
    loadChildren: './transactions/transactions.module#TransactionsModule',
    canActivate: [AuthGuardService],
  },
  // {
  //   path: 'revenue',
  //   loadChildren: './revenue/revenue.module#RevenueModule',
  //   canActivate: [AuthGuardService],
  // },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [AuthGuardService],
  },
  { path: 'wallets',
    loadChildren: './wallets/wallets.module#WalletsModule',
    canActivate: [AuthGuardService],
  },
  // { path: 'billers',
  //   loadChildren: './billers/billers.module#BillersModule',
  //   canActivate: [AuthGuardService],
  // },
  { path: 'payment-gateways',
    loadChildren: './gateways/gateways.module#GatewaysModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'verify-account',
    loadChildren: './verify-account/verify-account.module#VerifyAccountModule',
  },
  {
    path: 'forget-password',
    loadChildren: './forget-password/forget-password.module#ForgetPasswordModule',
  },
  {
    path: 'reset-password',
    loadChildren: './reset-password/reset-password.module#ResetPasswordModule',
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
  },
  {
    path: 'profile',
    component: EditPremiumAgentComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
