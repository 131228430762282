import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '@environment';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  static FORGOT_PASSWORD_URL = '/crm/users/auth/forgot-password';
  static RESET_PASSWORD_URL = '/crm/users/auth/reset-password';
  public USERS_APIS = {
    allUsers: 'crm/mobileusers',
    status: 'crm/mobileusers/total-stats',
    applyChange: 'crm/mobileuser/id/action',
    getUserProfile: 'crm/mobileuser/id',
    updateUserIdURL: 'crm/mobileuser/id',
    updateUser: 'crm/mobileuser/profile/id'
  };
  private host = environment.endPoint;

  constructor(private http: HttpClient, private router: Router) {
  }


  public getUsers(params) {
    let urlParams = '';
    const size = 'len=7';
    Object.keys(params).forEach((key) => {
      urlParams = urlParams + '&' + key + '=' + params[key];
    });
    return this.http.get(this.host + this.USERS_APIS.allUsers + '?' + size + urlParams);
  }

  public getStatus(par) {
    const params = JSON.parse(JSON.stringify(par));
    delete params.page;
    delete params.srt;
    delete params.srtd;

    let urlParams = '';
    Object.keys(params).forEach((key, i) => {
      if (i === 0) {
        urlParams = '?' + key + '=' + params[key];

      } else {
        urlParams = urlParams + '&' + key + '=' + params[key];
      }

    });
    return this.http.get(this.host + this.USERS_APIS.status + urlParams);
  }

  public applyAction(id, action) {
    return this.http.put(this.host + this.USERS_APIS.applyChange.replace('id', id).replace('action', action), {});
  }

  public getUserProfile(id) {
    return this.http.get(this.host + this.USERS_APIS.getUserProfile.replace('id', id));
  }

  public updateUserIdURL(id, body) {
    return this.http.put(this.host + this.USERS_APIS.updateUserIdURL.replace('id', id), body);
  }

  public updateUserInfo(user, userId, nationalId) {
    const body: any = {
      firstName: user.firstName,
      secondName: user.secondName,
      thirdName: user.thirdName,
      lastName: user.lastName,
    };
    if (nationalId !== user.nationalId) {
      body.nationalId = user.nationalId;
    }
    return this.http.put(this.host + this.USERS_APIS.updateUser.replace('id', userId), body);
  }


  forgotPassword(email: string) {
    const requestBody = {
      emailAddress: email,
      targetPageUrl: `${window.location.origin}/reset-password`,
    };

    return this.http.post(environment.endPoint + UsersService.FORGOT_PASSWORD_URL, requestBody);
  }


  resetPassword(param: object) {


    return this.http.post(environment.endPoint + UsersService.RESET_PASSWORD_URL, param);
  }
}


