<div class="Sidebar" *ngIf="router.url !== '/login'" >
  <ul>
    <li>
      <a routerLink="/dashboard"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/dashboard') ? 'dashboard' : 'dashboard-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.DASHBOARD' | translate }}
      </a>
    </li>
    <li>
      <a routerLink="/vouchers/stores">
        <maalchat-crm-icon-asset
          *ngIf="currentUrl && currentUrl.startsWith('/vouchers')"
          [name]="'stores-active'">
        </maalchat-crm-icon-asset>
        <maalchat-crm-icon-asset type="svg"
                                 width="30"
                                 height="25"
                                 *ngIf="currentUrl && !currentUrl.startsWith('/vouchers')"
                                 [name]="'stores'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.VOUCHERS' | translate }}
        <img *ngIf="currentUrl && !currentUrl.startsWith('/vouchers')" style="width: 8px; height: 15px; margin-left: 10px" src="../../../../assets/img/left-arrow.png">
        <img *ngIf="currentUrl && currentUrl.startsWith('/vouchers')" style="width: 15px; height: 8px; margin-left: 10px" src="../../assets/img/down-arrow.png">
      </a>
    </li>
    <li class="sunItem" *ngIf="currentUrl && currentUrl.startsWith('/vouchers')">
      <a routerLink="/vouchers/stores" routerLinkActive="Active">
        All Stores
      </a>
    </li>
    <li class="sunItem" *ngIf="currentUrl && currentUrl.startsWith('/vouchers')">
      <a routerLink="/vouchers/orders"
         routerLinkActive="Active">
        Orders
      </a>
    </li>
    <li class="sunItem" *ngIf="currentUrl && currentUrl.startsWith('/vouchers')">
      <a routerLink="/vouchers/mtb"
         routerLinkActive="Active">
        MTB Account
      </a>
    </li>

<!--    <li *ngIf="userType === 'SYSTEM_USER'">-->
<!--        <a routerLink="/premiumagents"-->
<!--           routerLinkActive="Active">-->
<!--          <maalchat-crm-icon-asset-->
<!--            [name]="currentUrl && !currentUrl.startsWith('/premiumagents') ? 'premiumagents' : 'premiumagents-active'">-->
<!--          </maalchat-crm-icon-asset>-->
<!--          {{'SIDEBAR.PREMIUM_AGENTS' | translate }}-->
<!--        </a>-->
<!--      </li>-->
    <li>
      <a routerLink="/agents"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/agents') ? 'agents' : 'agents-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.AGENTS' | translate }}
      </a>
    </li>
<!--    <li *ngIf="userType === 'SYSTEM_USER'">-->
<!--      <a routerLink="/merchants">-->
<!--        <maalchat-crm-icon-asset-->
<!--          [name]="currentUrl && !currentUrl.startsWith('/merchants') ? 'merchants' : 'merchants-active'">-->
<!--        </maalchat-crm-icon-asset>-->
<!--        {{'SIDEBAR.MERCHANTS' | translate }}-->
<!--        <img *ngIf="currentUrl && !currentUrl.startsWith('/merchants')" style="width: 8px; height: 15px; margin-left: 50px" src="../../../../assets/img/left-arrow.png">-->
<!--        <img *ngIf="currentUrl && currentUrl.startsWith('/merchants')" style="width: 15px; height: 8px; margin-left: 50px" src="../../assets/img/down-arrow.png">-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="sunItem" *ngIf="userType === 'SYSTEM_USER' && currentUrl && currentUrl.startsWith('/merchants')">-->
<!--      <a routerLink="/merchants/all"-->
<!--         routerLinkActive="Active">-->
<!--        {{'SIDEBAR.ALL_MERCHANTS' | translate }}-->
<!--      </a>-->
<!--    </li>-->
<!--      <li class="sunItem" *ngIf="userType === 'SYSTEM_USER' && currentUrl && currentUrl.startsWith('/merchants')">-->
<!--      <a routerLink="/merchants/mtb"-->
<!--         routerLinkActive="Active">-->
<!--        {{'SIDEBAR.MTB_ACCOUNT' | translate }}-->
<!--      </a>-->
<!--    </li>-->
    <li>
      <a routerLink="/transactions"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/transactions') ? 'transactions' : 'transactions-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.TRANSACTIONS' | translate }}
      </a>
    </li>
<!--    <li *ngIf="userType === 'SYSTEM_USER'">-->
<!--      <a routerLink="/revenue"-->
<!--         routerLinkActive="Active">-->
<!--        <maalchat-crm-icon-asset-->
<!--          [name]="currentUrl && !currentUrl.startsWith('/revenue') ? 'transactions' : 'transactions-active'">-->
<!--        </maalchat-crm-icon-asset>-->
<!--        {{'SIDEBAR.REVENUE' | translate }}-->
<!--      </a>-->
<!--    </li>-->
    <li>
      <a routerLink="/users"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/users') ? 'users' : 'users-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.USERS' | translate }}
      </a>
    </li>
    <li>
      <a routerLink="/wallets"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/wallets') ? 'wallets' : 'wallets-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.WALLETS' | translate }}
      </a>
    </li>
<!--    <li *ngIf="userType === 'SYSTEM_USER'">-->
<!--      <a routerLink="/billers">-->
<!--        <maalchat-crm-icon-asset-->
<!--          [name]="currentUrl && !currentUrl.startsWith('/billers') ? 'billers' : 'billers-active'">-->
<!--        </maalchat-crm-icon-asset>-->
<!--        {{'SIDEBAR.BILLERS' | translate }}-->
<!--        <img *ngIf="currentUrl && !currentUrl.startsWith('/billers')" style="width: 8px; height: 15px; margin-left: 73px" src="../../../../assets/img/left-arrow.png">-->
<!--        <img *ngIf="currentUrl && currentUrl.startsWith('/billers')" style="width: 15px; height: 8px; margin-left: 73px" src="../../assets/img/down-arrow.png">-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="sunItem" *ngIf="userType === 'SYSTEM_USER' && currentUrl && currentUrl.startsWith('/billers')">-->
<!--      <a routerLink="/billers/list"-->
<!--         routerLinkActive="Active">-->
<!--        {{'SIDEBAR.ALL_BILLERS' | translate }}-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="sunItem" *ngIf="userType === 'SYSTEM_USER' && currentUrl && currentUrl.startsWith('/billers')">-->
<!--      <a routerLink="/billers/mtb"-->
<!--         routerLinkActive="Active">-->
<!--        {{'SIDEBAR.MTB_ACCOUNT' | translate }}-->

<!--      </a>-->
<!--    </li>-->
    <li>
      <a routerLink="/payment-gateways"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/payment-gateways') ? 'gateways' : 'gateways-active'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.P-GATEWAY' | translate }}
      </a>
    </li>
    <li>
    <li>
      <a routerLink="/contact"
         routerLinkActive="Active">
        <maalchat-crm-icon-asset
          [name]="currentUrl && !currentUrl.startsWith('/contact') ? 'CONTACT' : 'CONTACT-ACTIVE'">
        </maalchat-crm-icon-asset>
        {{'SIDEBAR.CONTACT' | translate }}
      </a>
    </li>
  </ul>
</div>
