import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from '@auth/auth.service';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StationsService {

  constructor(private http: HttpClient, private router: Router) { }


  searchStations(searchKey: string, page: number, size: number, filter: string, srt: string, srtd: string, city: string, company: string) {
    // let  url = environment.endPoint + 'crm/top-ups/browsing/listing?page=' + page + '&len=' + size ;
    let url = environment.endPoint + 'crm/top-ups/browsing/listing?page=' + page + '&len=' + size + '&searchKey=' + searchKey + '&filter=' + filter + '&srt=' + srt + '&srtd=' + srtd + '';
    if ( city != 'all'  && city != undefined && city != null) {
      url = url + '&city=' + city;
    }
    console.log(  "company " + company )
    if ( company != 'all' && company != undefined && company != null) {
      url = url + '&company=' + company;
    }
    return this.getListByUrl(url);
  }

  statusChange(stationId: any) {

    return this.http.put(environment.endPoint + '/crm/top-ups/' + stationId + '/status', null);
  }

  getListByUrl(url) {

    try {

      return this.http.get(url);

    }
    catch (err) {
      this.isUnauthorized(err)
      return Observable.throw(err);

    }

  }


  getCompanies() {
    return this.http.get(environment.endPoint + 'crm/agent-companies/browsing/names');

  }



  isUnauthorized(err) {
    if (err.status === 401) {
      this.router.navigateByUrl('/login');
    }
  }
}
